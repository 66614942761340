@import '../node_modules/@kpmg-ux/ngx-tax-design-system/styles/css/tokens/mixins';
@import '../node_modules/@kpmg-ux/ngx-tax-design-system/styles/css/base/fieldset';

/* You can add global styles to this file, and also import other style files */
:root{
    --color-slab-0: RGBA(var(--color-blue-800)) 0%,
                    RGBA(var(--color-blue-900)) 100%;   
    --color-slab-1: RGBA(var(--color-blue-base),1);
    // --color-slab-2: RGBA(var(--color-purple-base),1);
    --color-slab-2: RGBA(var(--color-med-blue-base),1);
    --color-slab-3: RGBA(var(--color-blue-900),1);
    --color-slab-4: RGBA(var(--color-blue-1000),1);
  }



* {
  scroll-behavior: smooth;
  line-height: 1.5;
}
   

.appHeader {
@include layout(row, center, flex-start);
width: 100%;
  flex: 0 0 100px; // Default size and resize behavior
  // background: RGBA(var(--color-med-blue-600),1); // Default color
  background: RGBA(var(--color-header),0); // Default color
  z-index: 1;
  .appHeader__brand {
      .brand__title {
          white-space: nowrap;
          line-height: 1;
          margin-left: 1.6rem;
      } 
  }
  .appHeader__nav { 
      flex: 1;
  }
  .appHeader__actions {
  }
  .headerItem { 
      color: #ffffff; 
      background-color: RGBA(var(--color-blue-base),0); 
      transition: all 200ms;
      border-bottom: 0px;
      @include layout(row, center, center);
      &:hover {
        background-color: RGBA(var(--color-blue-base),1);
      }
      .headerItem__icon {
        font-size: 1.8rem;
      }
      .headerItem__text {
        line-height: 1;  
      }
  }
}

